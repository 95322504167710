<template>
  <div class="pt-4 px-2 px-md-0">
    <div class="mb-3 d-flex justify-space-between flex-wrap">
      <span :class="isDesktop ? 'fz-28': 'text-h5'" class="font-weight-semi-bold">
        Payment History
      </span>
      <div class="d-flex align-center">
        <div style="width: 130px;">
          <span>From:</span>
          <date-picker-solo :value="formatPickerDate(filter.startDate)" @changeDate="changeStartDate"/>
        </div>
        <div class="ml-4" style="width: 130px;">
          <span>To:</span>
          <date-picker-solo :value="formatPickerDate(filter.endDate)" @changeDate="changeEndDate"/>
        </div>
      </div>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        item-key="id"
        hide-default-footer
        disable-sort
      />
      <v-divider/>
    </div>
    <div v-if="itemsTotal > 10" class="d-flex justify-space-between bg-white">
      <div class="ma-5 br-3 grey-button d-flex">
        <template v-for="index in countPage">
          <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
          <v-btn
            :class="{'grey-button--selected': index === page}"
            text
            tile
            min-width="56"
            class=""
            :key="index"
            @click="pagination(index)"
          >{{ index }}
          </v-btn>
        </template>
      </div>

      <div class="ma-5 br-3 grey-button d-flex">
        <v-btn
          text
          tile
          class="px-0"
          min-width="36"
          width="36px"
          @click="previousPage(page)"
        >
          <v-icon small>
            navigate_before
          </v-icon>
        </v-btn>
        <v-divider vertical/>
        <v-btn
          text
          tile
          min-width="36"
          class="light-text px-0"
          width="36px"
          @click="nextPage(page)"
        >
          <v-icon small>
            navigate_next
          </v-icon>
        </v-btn>
      </div>
    </div>
    <vue-snotify/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import DatePickerSolo from '@/components/DatePickerSolo'

export default {
  name: 'PaymentsHistory',
  components: {
    DatePickerSolo
  },
  data () {
    return {
      items: [],
      loading: false,
      page: 1,
      itemsTotal: null,
      headers: [
        {
          text: 'Time',
          align: 'start',
          sortable: true,
          value: 'payOn',
          class: ['text-caption', 'greyBuoyant'],
          cellClass: ['font-weight-semi-bold', 'text-body-2', 'dark-cyanBlue']
        },
        {
          text: 'Numbers',
          align: 'start',
          sortable: false,
          value: 'number',
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant'
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: true,
          value: 'amount',
          class: ['text-caption', 'greyBuoyant'],
          cellClass: ['font-weight-semi-bold', 'text-body-2', 'dark-cyanBlue']
        },
        {
          text: 'Plan',
          align: 'start',
          sortable: false,
          value: 'durationUnit',
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant'
        },
        {
          text: 'Pay Point',
          align: 'start',
          sortable: false,
          value: 'payPoint',
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant'
        }
      ],
      filter: {
        offset: 0,
        limit: 10,
        startDate: null,
        endDate: null,
        orderBy: 'payOn',
        orderType: 'DESC'
      },
      timeOptions: {
        start: { hour: 0, minute: 0, second: 0, millisecond: 0 },
        end: { hour: 23, minute: 59, second: 59, millisecond: 59 },
        format: 'YYYY-MM-DD HH:mm:ss'
      }
    }
  },
  computed: {
    countPage () {
      return Math.ceil(this.itemsTotal / this.filter.limit)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  created () {
    this.setDate()
    // this.loadPaymentMethods()
    this.getHistory()
  },
  methods: {
    ...mapActions({
      getList: 'getPaymentsHistory'
      // getPaymentMethods: 'getPaymentMethods'
    }),
    /* async loadPaymentMethods () {
      try {
        await this.getPaymentMethods()
      } catch {
        this.$snotify.notifications = []
        this.$snotify.error('Please add card to display history.')
      }
    }, */
    async getHistory (filter = this.filter) {
      try {
        this.loading = true

        const { data } = await this.getList(filter)

        // this.items = data.list
        this.itemsTotal = data.total

        this.items = data.list.map(item => ({
          ...item.payment,
          number: item.stripeTransaction?.didNumbers[0].didNumber,
          payOn: this.formatDate(item.stripeTransaction ? item.stripeTransaction.paidOn : item.payment.payOn),
          amount: '$' + parseFloat(item.stripeTransaction ? item.stripeTransaction.amount : item.payment.amount).toFixed(2),
          durationUnit: item.stripeTransaction?.durationUnit,
          payPoint: item.payment?.payPoint?.replace('_', ' ')
        }))
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    setDate (value) {
      var date = new Date()
      this.filter.startDate = moment(value ? value[0] : new Date(date.getFullYear(), date.getMonth(), 1)).set(this.timeOptions.start).format(this.timeOptions.format)
      this.filter.endDate = moment(value ? value[1] : Date.now()).set(this.timeOptions.end).format(this.timeOptions.format)
    },
    changeStartDate (value) {
      if (new Date(value) > new Date(this.filter.endDate)) {
        this.filter.startDate = `${this.filter.endDate}`
        this.filter.endDate = moment(value || Date.now()).set(this.timeOptions.start).format(this.timeOptions.format)
      } else this.filter.startDate = moment(value || Date.now()).set(this.timeOptions.start).format(this.timeOptions.format)
      this.getHistory()
    },
    changeEndDate (value) {
      if (new Date(value) < new Date(this.filter.startDate)) {
        this.filter.endDate = `${this.filter.startDate}`
        this.filter.startDate = moment(value || Date.now()).set(this.timeOptions.end).format(this.timeOptions.format)
      } else this.filter.endDate = moment(value || Date.now()).set(this.timeOptions.end).format(this.timeOptions.format)
      this.getHistory()
    },
    pagination (index) {
      if (index) this.page = index

      this.filter.offset = (this.page - 1) * 10

      this.getHistory(this.filter)
    },
    previousPage (page) {
      console.log(page, 'page')
      if (page !== 1) {
        this.page = page - 1
      }

      this.pagination()
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }

      this.pagination()
    }
  }
}
</script>

<style>

</style>
